header a {
  color: black;
  font-weight: 700;
  font-size: 2em;
  text-decoration: none;
}

header {
  padding: 1rem 5vw;
}

header button {
  background-color: #1976d2;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
}